// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic'

export const components = {
  acknowledgements: dynamic(() => import('./Acknowledgements')),
  acknowledgements_v2: dynamic(() => import('./AcknowledgementsV2')),
  acknowledgements_v3: dynamic(() => import('./AcknowledgementsV3')),
  article_colour_used: dynamic(() => import('./ArticleColourUsed')),
  article_image_full_width: dynamic(() => import('./ArticleImageFullWidth')),
  article_image_with_text: dynamic(() => import('./ArticleImageWithText')),
  banner: dynamic(() => import('./Banner')),
  banner_v2: dynamic(() => import('./BannerV2')),
  banner_with_text: dynamic(() => import('./BannerWithText')),
  checklist_block: dynamic(() => import('./ChecklistBlock')),
  colour_pdp_benefits: dynamic(() => import('./ColourPdpBenefits')),
  colour_pdp_benefits_icons: dynamic(() => import('./ColourPdpBenefitsIcons')),
  colour_pdp_guidance: dynamic(() => import('./ColourPdpGuidance')),
  colour_pdp_specs: dynamic(() => import('./ColourPdpSpecs')),
  content: dynamic(() => import('./Content')),
  faq: dynamic(() => import('./Faq')),
  faq_hub: dynamic(() => import('./FaqHub')),
  faq_v2: dynamic(() => import('./FaqV2')),
  featured_articles: dynamic(() => import('./FeaturedArticles')),
  four_column_card: dynamic(() => import('./FourColumnCard')),
  guidance_video_new: dynamic(() => import('./GuidanceVideoNew')),
  hero_block: dynamic(() => import('./HeroBlock')),
  home_page_slider_banner: dynamic(() => import('./HomePageSliderBanner')),
  homepage_inspiration_banner: dynamic(
    () => import('./HomepageInspirationBanner'),
  ),
  homepage_ksps: dynamic(() => import('./HomepageKsps')),
  homepage_scroll_slider: dynamic(() => import('./HomepageScrollSlider')),
  homepage_socialmedia: dynamic(() => import('./HomepageSocialmedia')),
  homepage_trust_bar: dynamic(() => import('./HomepageTrustBar')),
  hotjar_survey_block: dynamic(() => import('./HotjarSurveyBlock')),
  image_split: dynamic(() => import('./ImageSplit')),
  link_pannel: dynamic(() => import('./LinkPannel')),
  mega_nav_callout: dynamic(() => import('./MegaNavCallout')),
  mega_nav_column: dynamic(() => import('./MegaNavColumn')),
  more_docs_list: dynamic(() => import('./MoreDocsList')),
  nav_block: dynamic(() => import('./NavBlock')),
  nav_item: dynamic(() => import('./NavItem')),
  product_images: dynamic(() => import('./ProductImages')),
  product_slider: dynamic(() => import('./ProductSlider')),
  quote: dynamic(() => import('./Quote')),
  sauce_gallery: dynamic(() => import('./SauceGallery')),
  sauce_tv: dynamic(() => import('./SauceTv')),
  similar_colours: dynamic(() => import('./SimilarColours')),
  similar_products: dynamic(() => import('./SimilarProducts')),
  step_by_step_block: dynamic(() => import('./StepByStepBlock')),
  stockists: dynamic(() => import('./Stockists')),
  stockists_enquiry: dynamic(() => import('./StockistsEnquiry')),
  surface: dynamic(() => import('./Surface')),
  table: dynamic(() => import('./Table')),
  three_column_card: dynamic(() => import('./ThreeColumnCard')),
  tile_block: dynamic(() => import('./TileBlock')),
  trade_accounts_static_text: dynamic(
    () => import('./TradeAccountsStaticText'),
  ),
  video_block: dynamic(() => import('./VideoBlock')),
}
